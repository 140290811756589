<template>
<div id="blank">
  <div>
    <div class="mapsurround" ref="mapsurround">
      <l-map ref="map" :maxBounds="bounds" :center="mapcenter" :zoom="1" :zoomAnimation="true" :maxBoundsViscosity="0.0" @ready="fit()" :options="mapOptions">
          
        <l-image-overlay :url="'/map.svg'" :bounds="bounds"></l-image-overlay>
        <l-rectangle :bounds="[[84.1,-460],[79.6,-259]]" @click="openChapter(14)" :fill="true" :opacity="0" :fillOpacity="0"></l-rectangle>
        <l-rectangle :bounds="[[84.1,-98],[79.6,105.5]]" @click="openChapter(15)" :fill="true"  :opacity="0" :fillOpacity="0"></l-rectangle>
        <l-rectangle :bounds="[[84.1,147],[79.6,349]]" @click="openChapter(15)" :fill="true"  :opacity="0" :fillOpacity="0"></l-rectangle>

        <l-rectangle :bounds="[[73.9,147],[62,349]]" @click="openChapter(2)" :fill="true" :opacity="0" :fillOpacity="0"></l-rectangle>
        <l-rectangle :bounds="[[59.5,147],[40,349]]" @click="openChapter(2)" :fill="true" :opacity="0" :fillOpacity="0"></l-rectangle>
        <l-rectangle :bounds="[[36.2,147],[8,349]]" @click="openChapter(4)" :fill="true" :opacity="0" :fillOpacity="0"></l-rectangle>

        <l-rectangle :bounds="[[59.5,372],[40,503]]" @click="openChapter(5)" :fill="true" :opacity="0" :fillOpacity="0"></l-rectangle>


        <l-rectangle :bounds="[[-65,147],[-48,349]]" @click="openChapter(6)" :fill="true"  :opacity="0" :fillOpacity="0"></l-rectangle>
        <l-rectangle :bounds="[[-44.5,147],[-18,349]]" @click="openChapter(6)" :fill="true" :opacity="0" :fillOpacity="0"></l-rectangle>

        <l-rectangle :bounds="[[73.9,-98],[62,105]]" @click="openChapter(1)" :fill="true" :opacity="0" :fillOpacity="0"></l-rectangle>
        <l-rectangle :bounds="[[59.5,-98],[40,105]]" @click="openChapter(1)" :fill="true" :opacity="0" :fillOpacity="0"></l-rectangle>
        <l-rectangle :bounds="[[36.2,-98],[8,105]]" @click="openChapter(1)" :fill="true" :opacity="0" :fillOpacity="0"></l-rectangle>

        <l-rectangle :bounds="[[-37.5,-98],[-8,105]]" @click="openChapter(3)" :fill="true"  :opacity="0" :fillOpacity="0"></l-rectangle>

        <l-rectangle :bounds="[[73.9,-326],[62,-123]]" @click="openChapter(11)" :fill="true" :opacity="0" :fillOpacity="0"></l-rectangle>
        <l-rectangle :bounds="[[58,-326],[36,-123]]" @click="openChapter(10)" :fill="true" :opacity="0" :fillOpacity="0"></l-rectangle>
        <l-rectangle :bounds="[[30.5,-326],[0,-123]]" @click="openChapter(9)" :fill="true" :opacity="0" :fillOpacity="0"></l-rectangle>

        <l-rectangle :bounds="[[-37.5,-326],[-8,-123]]" @click="openChapter(3)" :fill="true" :opacity="0" :fillOpacity="0"></l-rectangle>

        <l-rectangle :bounds="[[46,-455],[19.5,-354.5]]" @click="openChapter(12)" :fill="true" :opacity="0" :fillOpacity="0"></l-rectangle>

        <l-rectangle :bounds="[[-63,-453],[-45,-251]]" @click="openChapter(12)" :fill="true" :opacity="0" :fillOpacity="0"></l-rectangle>
        <l-rectangle :bounds="[[-83.3,-453],[-78.5,-251]]" @click="openChapter(13)" :fill="true" :opacity="0" :fillOpacity="0"></l-rectangle>

        <l-rectangle :bounds="[[-70.3,-205.2],[-56,-4.5]]" @click="openChapter(8)" :fill="true" :opacity="0" :fillOpacity="0"></l-rectangle>
        <l-rectangle :bounds="[[-78.5,16],[-70,219]]" @click="openChapter(7)" :fill="true" :opacity="0" :fillOpacity="0"></l-rectangle>

      </l-map>
    </div>  
  </div>
  <div class="mapref">

    <div class="action" v-show="!showaudiokey">
      <Key></Key>
      <div class="links">
        <div class="linkbox">
      <ul>
        <li @click="showinfo=true">
         Download the Exhibition
        </li>
        <li @click="showaudiokey=true">
          Key to Audio Environment
        </li>
        <li>
          <a target="_blank" href="https://kidxstore.wasteheadquarters.com/" >Visit the Gift Shop</a>
        </li>
        <li>
          <a target="_blank" href="https://radiohead.ffm.to/kid-a-mnesia" >Explore the Soundtrack</a>
        </li>
        <li @click="showsignup=true">
           Find Out More
        </li>
      </ul>
      </div>
      </div>
    </div>

    <div class="actionaudio" v-show="showaudiokey">

      <div class="keybox">
        <button class="modal__close" @click="showaudiokey=false">
        </button>
      <ul class="ma1">
        <li class="key keya">TWISTED WOODS &amp; UNDER THE WOODS</li>
        <li class="key keyb">EVERYTHING IN ITS RIGHT PLACE</li>
        <li class="key keyc">KID A</li>
        <li class="key keyd">THE NATIONAL ANTHEM</li>
        <li class="key keye">PACKT LIKE SARDINES IN A CRUSHD TIN BOX</li>
        <li class="key keyf">IN LIMBO</li>
        <li class="key keyg">TREEFINGERS</li>
        <li class="key keyh">IDIOTEQUE</li>
        <li class="key keyi">HOW TO DISAPPEAR COMPLETELY/ PYRAMID SONG/ YOU AND WHOSE ARMY?</li>
        <li class="key keyj">LIKE SPINNING PLATES</li>
        <li class="key keyk">PULK PULL REVOLVING DOORS/ TRUE LOVE WAITS</li>
        <li class="key keyl">HUNTING BEARS</li>
        <li class="key keym">MOTION PICTURE SOUNDTRACK</li>
        <li class="key keyn">LITTLE CINEMA</li>
      </ul>
      </div>


    </div>

  </div>

  <vue-final-modal v-model="showbook" classes="modal-container" content-class="modal-content" :z-index="zi"  @click-outside="hidecat()">
    <div class="modal__content flipbook">
    <button class="modal__close" @click="hidecat()"></button>

      <div class="container">

      <div class="flip-book" id="guidebook" ref="guidebook" v-bind:class="{ portrait: !booklandscape }" style="margin: 50px auto;">
          <div class="page page-cover page-cover-top" data-density="hard">
              <div class="page-content flex flex-column justify-center items-center">
                <h1><KATitle></KATitle></h1>
                <h2>CATALOGUE</h2>
              </div>
          </div>
          <Page v-for="n in products.pages[products.pages.length-1].n" :number="n" :products="getproduct(n)" v-bind:key="n"></Page>
          <div class="page page-cover page-cover-bottom" data-density="hard">
              <div class="page-content">
                  <h2>THE END</h2>
              </div>
          </div>
      </div>
      
      <div id="navl" v-bind:class="{ portrait: !booklandscape }" @click="fprev()">
      </div>
      <div id="navr" v-bind:class="{ portrait: !booklandscape }" @click="fnext()">
      </div>

      </div>
    </div>
  </vue-final-modal>

  <vue-final-modal v-model="showinfo" classes="modal-container flex justify-center items-center" content-class="modal-content" @keydown.esc="closedl()" @click-outside="closedl()">
    <div class="modal__content titlepage relative">
    <button class="modal__close" @click="closedl()">
    </button>

    <div class="container h-100">
      <div class="flex flex-column h-100 ">
        <div class="tp1">
          <KATitle></KATitle>
        </div>
        <div class="tp2">
          <div>
          <p>An upside-down digital/analogue universe created from original artwork and recordings to commemorate 21 years of Kid A and Amnesiac.</p>

          <p>Download it free for PC, Mac or PS5:</p>
          </div>
        </div>
        <div class="tp3">

          <div class="cs  flex items-center justify-center">
            
            <div class="store pr4-ns egs">
              <a target="_blank" href="https://www.epicgames.com/store/p/kid-a-mnesia-exhibition" @click="closedl(1000)">
                <EGStore></EGStore>
                  <!--<img src="./assets/EGS_Logo_Dark.png"> -->
                <p style="font-size:97%">PC and Mac</p>
              </a>
            </div>
            <div class="store pl4-ns psf">
              <a target="_blank" href="https://store.playstation.com/concept/10003085" @click="closedl(1000)">
                <PSFive></PSFive>
              </a>
            </div>        
          </div>


        </div>
      </div>

    </div>
    </div>
  </vue-final-modal>

  <vue-final-modal v-model="showsignup" classes="modal-container flex justify-center items-center" content-class="modal-content" @keydown.esc="showsignup=false">
    <div class="modal__content titlepage waste relative">
    <button class="modal__close" @click="showsignup = false"></button>
    <div class="container h-100">
      <div class="flex flex-column h-100 ">
        <div class="tp1">
        <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAMAAACahl6sAAAB8lBMVEVpaWmfn5+vr6+vr6+9vb3FxcWbm5uEhISzs7Onp6dubm57e3vMzMxoaGiGhobe3t5+fn7d3d3f39/j4+NMTEyioqKkpKSoqKisrKyurq6wsLBCQkJKSkpiYmKSkpKVlZWWlpaZmZmenp61tbW7u7vf3981NTXCwsLn5+fIyMjJycnKysrNzc3h4eF/f3/Nzc3i4uIrKyssLCynp6fU1NTV1dXz8/Ompqa4uLjW1tbY2Njk5OTl5eVtbW2Pj4+zs7O7u7vAwMDb29s2NjZsbGzc3Nzk5OSMjIzIyMje3t7f39/u7u5ISEhjY2OEhITi4uLm5ubt7e319fWAgIDn5+fr6+vy8vLZ2dnNzc3Y2Nja2trp6elTU1Pq6uoVFRX7+/sSEhJmZmbV1dVKSkr7+/vj4+Pv7+9FRUWbm5vw8PDi4uLf39/y8vLz8/MUFBT9/f329vYyMjI5OTn29vb4+PgNDQ3///8ZGRn+/v4TExNHR0f4+Pj///8pKSkFBQUGBgb5+fk5OTn39/f8/Pz19fX8/PwdHR0sLCw4ODgNDQ0AAAAMDAz///8AAAASEhL7+/v+/v4REREkJCQlJSX9/f38/PwAAAD////+/v4LCwv///8XFxf9/f3+/v7///8HBwcFBQUAAAAAAAAQEBAaGhr///+bJFK2AAAAonRSTlOqra2vr7Cys7S2t7e4urq7vLy+vr+/v7+/v7/AwMDAwMDAwMDAwMHBwcLCwsLCw8PDxMTExMTExcXFxcXFxsbGxsbGx8fHx8jIyMjIycnJycnJycrKysrLzMzNzc7Oz8/Q0NDR0dLS09PT1dbW1tjY2tvb29vc3d7e39/f3+Di4uLk5OXm5ujo6Onu7u7v7+/v8PDw8PHy8vP09PX29vb4+f4spKWKAAAAAWJLR0SlLrlKLwAAAr9JREFUeNrt2+VzE0EYgPHg7hSXQnGX4u4UL+5eijuF4i4hlOJOKdD/k9Dd7DB3c5fb3F3Ibp7n42bzpr/Jh9u5XBM/LSkBBAgQIECAAAECBAgQIECA5ABZcl+j2T6Ddt4VdfbZczEzaFf0kBVNGs3wGXRS7unls6c6M+gEECBAgAABUqiQg3e8GmgW5LbnnqlAgAABAiQ+yOBBXi0s8iv77irRYtMh9XLlGBAgQIAAARIYMqZO9mrzgaM1NTdTqW1/X/71+83T66vyAhk1zbPvGhDVteblxmTy+cOXXx2vxQpp8N70OXdIugb3VwsECBAgQCKHPJK/im6PE3KpXjQ6LGS8HDTBDVnmfHdjrepyVJAHctDksJA5cmVuAMg/nQcCBAgQIEZDSspEy9VK+5np1lam++H9ttZtRJ3UypCloqFqZYscXXbV5/O7dhB1j/6IUsABAQIECBAg+YP03aNRic+gRVUazYrz5kPIu/H7dAZVAAECBAiQIoCc1rkgzk9l70OMkE91ztSP4U1HdAa9T2ZvXYwQdxdygwSpGggQIECARA358jp7h0yAlP/nIwoQIECAAAECJBZIx/3OztY6e2YCZG9UR0wgQIAAAaID2XAve0/CQvrJu9GTwkI2ykF9cruyh37wbI0cNCAs5KPrCTogQIAAAVIEkLHy34aG+ex5PC7TLe9NpcNF69VKl5YatcvHEaVgAgIECBAgQAyErD6l0fEChpzTOWsdBgIECBAgQCyFvAjwAHMLEyBBagsECBAgQIAEbsdb2btM38yEbDXziOJuky2QlbZA5tkCmWILZKItkJG2QHraAulhC+SK60nmhC1nrd5AgAABAgRIcxVnRKVqpdzMm9g35F80Qq10AwIECBAgQALXXz7jvECttKrUaLrhRxS9gAABAgQIECBAgAABAgRIWEjSkv4AVpZv+2nK6CMAAAAASUVORK5CYII=">
        </div>
        <div class="tp2">
          <div class="signupbox" v-if="!signupsuccess">
            <p>Sign up to the mailing list to receive exclusive news and information about Radiohead and their related projects as well as priority access to pre-sale tickets, music and merchandise:</p>
              <fieldset id="sign_up" class="b--none">
                <div class="mt3 dark-red f4" v-if="signuperror">
                  {{signuperror}}
                </div>
                <div class="mt3">
                  <label class="dw fw6 lh-copy pv2 white" for="email-address">Enter your email address:</label>
                  <input class="ma3 pa2 input-reset ba bg-transparent w-50-ns w-75  white" type="email" v-model="email">
                </div>
                <div class="mt0">      
                  <label class="pa0 ma0 f5 white pointer"><input type="checkbox" v-model="signuptc" value="1"> I agree with the <a href="https://www.wasteheadquarters.com/privacy-policy" target="_blank">privacy policy</a></label>
                </div>
              
              </fieldset>
              <div class="">
                <button class="b ph3 pv2 input-reset ba b--white bg-white black grow pointer dib" :disabled="!signupvalid" @click="submitjoin">SIGN UP</button>
              </div>
          </div>
          <div v-else>
            <div class="mt3 green f4" v-if="signupsuccess">
              {{signupsuccess}}
            </div>
          </div>
        </div>
        <div class="tp3">
          <p>Explore more of Radiohead at the <a href="https://www.radiohead.com/library">Public Library</a> and at <a href="https://wasteheadquarters.com">W.A.S.T.E. HQ</a>.</p>
        </div>
        </div>
    </div>
    </div>
  </vue-final-modal>

</div>
</template>

<script>
import {PageFlip} from 'page-flip'
import Page from './components/Page.vue'
import EGStore from './assets/egs.svg?inline';
import PSFive from './assets/ps5.svg?inline';
import Key from './assets/key.svg?inline';
import KATitle from './assets/katitle.svg?inline';
import productlist from './assets/products.json'

import { $vfm, VueFinalModal, ModalsContainer } from 'vue-final-modal'
import "leaflet/dist/leaflet.css"
import { LMap, LImageOverlay, LRectangle } from "@vue-leaflet/vue-leaflet";


export default {  
  name: 'App',
  data: () => ({
      map: null,
      showinfo: false,
      pageFlip: null,
      showbook: true,
      showaudiokey: false,
      showsignup: false,
      rootopen: null,
      zi: -100,
      bounds: [[-800,-800], [800, 800]],
      maxbounds:[[-800,-800], [800, 800]],
      mapcenter: [400,0],
      mapOptions: {
        zoomSnap: 0.25,
        zoomDelta: 0.25,
        scrollWheelZoom: 'center'
      },
      signuperror: null,
      signupsuccess: null,
      signuptc: null,
      email: null,
      products: productlist,
      booklandscape: true
  }),
  methods: {
    openChapter: function (chapter_id) {
      return;
      if (chapter_id>14 || chapter_id<0) {
        chapter_id = Math.floor(Math.random() * (14 - 1 + 1) + 1)
      }
      let chapter = productlist.pages.filter(d => d.c === chapter_id)[0];
      let firstpage = chapter.n;
      if (!this.booklandscape) {
        this.pageFlip.turnToPage(firstpage);
      }
      else {
        this.pageFlip.flip(firstpage);
      }
      this.showbook = true;
      this.zi = 1000;
    },
    fit: function() {
      let height = this.$refs.mapsurround.clientHeight;
      let width = this.$refs.mapsurround.clientWidth;
      
      let ar = (width/height);
      let zoom = 1;
      let map = this.$refs.map.leafletObject;

      let requiredwidth = this.$refs.mapsurround.clientHeight * 2.81;
      
      if (height >= 480 && height < 520) { zoom = height / 480; }
      if (height < 480) { zoom = (height / 590) - 0.1; }

      if (zoom < 0.4) { zoom = 0.4; }

      if (width >= requiredwidth && height > 490) {
        map.setZoom(zoom);
        map.removeControl(this.$refs.map.leafletObject.zoomControl);
        map.touchZoom.disable();
        map.doubleClickZoom.disable();
        map.scrollWheelZoom.disable();
        map.boxZoom.disable();
        map.keyboard.disable();
        map.dragging.disable();
        return;
      }   

      map.setZoom(zoom);

    },
    closedl: function(timeout = 0) {
      setTimeout(() => {
        this.showinfo=false;
        this.$cookie.set('showdl',1,0);
      }, timeout);
    },
    showcat: function() {
      this.showbook = true
      this.zi = 1000
    },
    hidecat: function() {
      this.zi = -1000
    },
    fprev: function() {
      if (!this.booklandscape) {
      this.pageFlip.turnToPrevPage();
      } else {
      this.pageFlip.flipPrev();
      }
    },
    fnext: function() {
      if (!this.booklandscape) {
      this.pageFlip.turnToNextPage();
      } else {
      this.pageFlip.flipNext();
      }
    },
    getproduct: function (page) {
      return productlist.pages.filter(d => d.n === page)[0].products;
    },
    async submitjoin () {
      this.signuperror = null
      const r = await this.$http.post('https://signup.sandbagheadquarters.com/signup', {email: this.email, list: 1, source: 'kx'})
      if (r.data.error) {
        this.signuperror = r.data.error
      } else {
        this.signupsuccess = r.data.success
      }
    }
  },
  computed:  {
    signupvalid:function () {
    return this.signuptc==1 && this.email != "";
    }
  },
  mounted: function() {
    let patharray = window.location.hash.split('/');
    
    if (patharray.length==3 && patharray[1]=="catalogue") {
      this.rootopen = parseInt(patharray[2]);
    } else {
      this.showinfo = !(this.$cookie.get('showdl'));
    }

    if (patharray.length>1 && patharray[1]=="download") {
      this.showinfo = true;
    }

    this.$nextTick(() => {
      // decide if its portrait or not
      let ar = (window.innerWidth / innerHeight);
      let usep = false;
      let autosize = true;
      
      if (ar > 1.08) {
        var height = (window.innerHeight) - 100;
        var width = (height * (4/3))/2;
        var maxheight = height;
        var maxwidth = width;
        this.booklandscape = true;
      } else {
        var height = (window.innerHeight) - 140;
        var width = (height * (4/3))/2;
        var maxheight = height / 2;
        var maxwidth = width/2;
        usep = true;
        autosize = false;
        this.booklandscape = false;
      }
      this.pageFlip = new PageFlip(
          document.getElementById("guidebook"),
          {
              width: width, // base page width
              height: height, // base page height
              size: "fixed",
              minWidth: width,
              maxWidth: maxwidth,
              minHeight: height,
              maxHeight: maxheight,
              autoSize: autosize,
              flippingTime: 500,
              usePortrait: usep,
              maxShadowOpacity: 0.2, // Half shadow intensity
              showCover: true,
              mobileScrollSupport: true,
              disableFlipByClick: true
          }
      );
      this.pageFlip.loadFromHTML(document.querySelectorAll(".page"));
      if (this.booklandscape) {
        this.pageFlip.on('changeState', (e) => {
          let idx = this.pageFlip.getCurrentPageIndex();
          if (e.data=="fold_corner" && idx>0 && idx<(this.pageFlip.getPageCount()+1) ) {
            this.$refs.guidebook.style.overflow = 'hidden';
          } else {
            this.$refs.guidebook.style.overflow = 'inherit';
          }
        });
      }
      if (this.rootopen) {
        this.openChapter(this.rootopen);
        this.showcat();
      }
    });

  },

  components: {
    Key,
    Page,
    EGStore,
    PSFive,
    VueFinalModal,
    ModalsContainer,
    LMap,
    LImageOverlay,
    LRectangle,
    KATitle
  }
}
</script>

<style>
@import './css/book.css';
@import './css/page.css';
@import './css/tachyons.css';
</style>