
<template>
<div class="page">
  <div class="page-content">

    <template v-for="product in products" v-bind:key="product.n">
      <div class="page-image">
        <a :href="product.url"><img :src="require(`@/assets/catalogue/${product.image}`)"/></a>
      <div class="page-caption"><a :href="product.url"><div v-text="product.name"></div></a></div>
      </div>
    </template>

  </div>
  <div class="page-number">[ {{number}} ]</div>
</div>
</template>


<script>
export default {
  props: ['number', 'products'],
  methods: {
    gotourl: function (url) {
      this.$emit("gotourl", url);
    }
  }
}
</script>